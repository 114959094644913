import React from 'react'
import css from "../style/about.module.css"
import AboutusBanner from './AboutusBanner'

function ContactUs() {
  return (
    <main className={css.privacyPolicy}>
        <AboutusBanner page={"Contact Us"}/>
        <div className={css.Header}>
            <h1>Contact <span>Us</span></h1>
            <span className={css.divider}>
                <hr />
                <i className="fa-regular fa-square"></i>
                <hr />
            </span>
        </div>
        <div className={css.content}>
            <h2>Address</h2>
           <p>SCA Marriage,38/4,Sankagiri Tiruchengode Road,<br/>Opp SPM Hospital Road,<br/>Pattaraimedu,<br/>Tiruchengode,<br/>Namakkal,<br/>Tamil Nadu - 637211.</p>
            <br />
            <p><strong>Mobile Number:</strong> +91 9750052555</p>
            <p><strong>Email:</strong> ssvgr7@gmail.com</p>

        </div>
    </main>
  )
}

export default ContactUs